<template>
  <div>
<!-- {{subCategory}} -->
    <ag-grid-vue
      style="width: 100%; height: 450px"
      class="ag-theme-alpine"
      :columnDefs="columnDefs"
      :rowData="rowData"
      :rowSelection="rowSelection"
      :setQuickFilter="updateSearchQuery"
      :gridOptions="gridOptions"
      @grid-ready="onGridReady"
      suppressDragLeaveHidesColumns
      suppressMovableColumns
      checkboxSelection="true"
    >
    </ag-grid-vue>
  </div>
</template>

<script>
/* eslint-disable vue/no-unused-components */
import { AgGridVue } from "ag-grid-vue";
import { mapGetters } from "vuex";
import Cookie from "js-cookie";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import iconEditCheck from "../Table/components/iconEditCheck.vue";
import iconDetailSubAsset from "../Table/components/iconDetailSubAsset.vue";
import iconDeleteCheck from "../Table/components/iconDeleteCheck.vue";
export default {
  components: {
    AgGridVue,
    iconEditCheck,
    iconDetailSubAsset,
    iconDeleteCheck,
  },
  data() {
    return {
      columnDefs: null,
      rowSelection: "multiple",
      gridOptions: {
        rowHeight: 50,
        defaultColDef: {
          resizable: true,
        },
      },
      gridApi: "sizeColumnsToFit",
      id_user: null,
    };
  },
  props: {     
    searchText: {
      type: String,
    }, },
  beforeMount() {
    this.columnDefs = [
      {
        headerName: this.$t("count.order"),
        field: "index",
        headerCheckboxSelection: true,
        headerCheckboxSelectionFilteredOnly: true,
        checkboxSelection: true,
        maxWidth: 110,
        valueGetter: "node.rowIndex + 1",
        getQuickFilterText: (params) => {
          return "";
        },
      },
      {
        headerName: this.$t("branch.code_name"),
        field: "name",
        sortable: true,
        minWidth: 200,
      },

      {
        headerName: this.$t("branch.edit_name"),
        field: "edit",
        cellRendererFramework: "iconEditCheck",
        maxWidth: 130,
        suppressSizeToFit: true,
      },
      {
        headerName: this.$t("branch.delete"),
        field: "delete",
        cellRendererFramework: "iconDeleteCheck",
        maxWidth: 130,
        suppressSizeToFit: true,
      },
    ];
  },
  computed: {
    ...mapGetters({
      branch: "branch",
    }),
    rowData() {
      return this.branch.map((branch, index) => {
        return {
          name: branch.name,
          edit: {id_user: branch.branch_id},
          delete: branch,
        };
      });
    },
  },
  watch: {
    searchText:function searchText() {
      this.updateSearchQuery(this.searchText);
    },
  },
  mounted() {
    // alert(this.$route.params.id_category)
    let cookie = Cookie.get("cookielogin");
    if (cookie) {
      let cookiejson = JSON.parse(cookie);
      this.id_user = cookiejson.id_user;
    }
    this.gridApi = this.gridOptions.api;
  },
  methods: {
    onGridReady() {
      this.gridApi.sizeColumnsToFit();
    },
    getSelectedRowData() {
      let selectedNodes = this.gridApi.getSelectedNodes();
      let selectedData = selectedNodes.map((node) => node.data);
      return selectedData;
    },
    updateSearchQuery(val) {
      this.gridApi.setQuickFilter(val);
      this.gridApi.refreshCells();
    },
  },
};
</script>

<style scoped>
* >>> .ag-header-cell-label {
  justify-content: center;
}
* >>> .ag-header-row-column {
  background: transparent linear-gradient(180deg, #007afe 0%, #013f81 100%);
  color: white;
}
</style>
